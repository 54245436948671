<template>
  <div class="search-hints">
    <input
      id="ssearch"
      ref="ssearch"
      v-model="searchQuery"
      :class="{ active: searchQuery }"
      @input="trottled"
      @focus="focused = true"
      @blur="focused = false"
      type="search"
      name="ssearch"
      autocomplete="off"
      placeholder="Поиск по сайту..."
    >
    <!-- О чем хотите почитать? -->
    <div v-if="searchQuery.length > 2" class="datalist">
      <div v-if="!searchHints.length" class="no-match">
        <span>Ничего не найдено</span>
      </div>
      <ul v-else>
        <nuxt-link
          v-for="(item, i) in searchHints"
          :to="`/article/${item.pretty}`"
          :key="`sh-${i}`"
          tag="li"
        >
          <strong>{{ item.title }}</strong>
        </nuxt-link>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  watch: {
    route() {
      this.searchQuery = ''
      this.searchHints = []
    }
  },
  computed: {
    route() {
      return this.$route.fullPath
    }
  },
  data() {
    return {
      searchQuery: '',
      searchHints: []
    }
  },
  methods: {
    trottled() {
      if (!this.searchQuery.trim()) return
      let oldValue = ''
      setTimeout(() => {
        if (
          oldValue === this.searchQuery &&
          this.searchQuery !== '' &&
          this.searchQuery.length > 2
        ) {
          this.getSearchHints()
        }
      }, 200)
      oldValue = this.searchQuery
    },
    async getSearchHints() {
      let url = `/api/searchhints?value=${encodeURIComponent(this.searchQuery)}`
      if (this.type) url += `&type=${this.type}`
      const { data } = await this.$axios.get(url)
      if (data.error) {
        this.$store.commit('setError', { status: 'warn', msg: data.error.message })
        setTimeout(() => this.$store.commit('clearError'), 2000)
      } else {
        this.searchHints = data
      }
    }
  }
}
</script>

<style lang="stylus">
.search-hints
  padding: 40px 40px 0px
  position: relative
  background-color: #fff
  input
    width: 100%
    padding: 10px 14px
    font-size: 1.8rem
    border: 2px solid rgba(0, 0, 0, 0.1)
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2)
  .datalist
    position: absolute
    width calc(100% - 28px)
    background-color #fff
    color #000
    border 1px solid #000
    border-top none
    z-index 4
    .no-match
      padding 20px
      a
        color #000
    ul
      list-style none
      padding 10px 0px
      max-height 400px
      overflow auto
      // box-shadow 0 0 10px 0px rgba(0 0 0 0.2)
      li
        padding 7px 14px
        display block
        width 100%
        font-size 1.4rem
        border none
        cursor pointer
        transition: color .2s ease
        &:hover
          color: #e00a1e
</style>
